$background: #282828;
$boxcolor: #494949;
$lines: #800080;


//White theme


//dark theme

.webbody {
  background-color: #282828;
  color: white;
  min-height: 100vh;

}


.background-image {
  height: 100vh;
  width: 100vw;

  .homepage {
    background-color: rgb(21 21 21);
    width: 100vw;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    z-index: 100;
    flex-direction: column;

    .links {
      display: flex;
      gap: 5rem;
    }

    a {
      font-size: 33px;
      color: white;
      text-decoration: none;

    }

    a:hover {
      color: blue;
    }
  }
}


.line {
  width: 300px;
  height: 2px;
  background-color: purple;

}

.link-box {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
}

main {

  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    max-width: 1200px;
    margin: 3rem;
  }

  .section-projects {
    max-width: 1280px;
  }

  .img-icons {
    width: 30px;
    height: 30px;
    margin: 0.5rem;

    object-fit: contain;
  }

  .icons {
    display: flex;
    justify-content: center;
    padding: 1rem;


    svg {
      display: inline-block;
      width: 50px;
      height: 50px;
      transition: fill 0.2s ease-in-out;
      background-size: cover;
    }
  }

  .icons a:hover svg {
    fill: purple;
  }

  .p-text {
    p {
      margin: 20px;
      font-size: 22px;
    }
  }

  .section-header {
    display: flex;
    align-items: center;
  }

  svg {
    padding: 0.5rem;
    font-size: 18px;
  }


  .load-content {
    position: absolute;
    top: 50;
  }



  .about-me-p {
    font-size: 18px;
    margin: 1%;
    padding: 0%;
    display: flex;
    align-items: center;
  }

  .profile-img {
    padding: 1rem;
    float: left;
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }

  h2 {
    font-size: 40px;
  }

  #aboutme {
    margin-top: 3rem;
  }
}

//Image Gallery picker

//enlarged image animation + blur and other stuff
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.big-img-enlarged {
  width: 80vw;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border: solid 5px rgba(181, 200, 221, 0.574);
}

// .enlarged-image-container{
//   display: flex;
//   justify-content: center;
//   width: 100vw;
//   height: 100vh;
// }

.small-text {
  font-size: 10px;
  opacity: 0.5;
  margin: 0;
  display: flex;
  justify-content: center;
}



.image-gallery {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .big-img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }

  .selected {
    opacity: 1 !important;
  }

  .small-img {
    object-fit: cover;
    opacity: 0.4;
  }

  .small-img:hover {
    opacity: 0.8;
  }

  .selectable-img {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1rem;

    img {
      width: 100px;
      height: 100px;
    }
  }
}


.onepost-section {
  margin-top: 10rem;
  max-width: 800px;

  a {
    color: rgb(162, 162, 255) !important;
  }
}


//singlepost header
.header-post-3d {
  position: relative;
  display: flex;
  justify-content: center;
  height: 200px;


}

.header-post-3d::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 1;
  // background-color: #464646;
  backdrop-filter: blur(2px) saturate(100%);
}

.header-post-3d img {
  position: relative;
  width: 100%;
  object-fit: cover;
}




.svg-wave {
  z-index: 0;
  position: absolute;
  width: 100%;
  padding: 0;
  top: 3.5rem;
  fill: #232323;
}

.svg-wave-bot {
  fill: #232323;
  width: 100%;
  z-index: 0s;
}

.dark-color {
  background-color: #232323;
  height: 10px;
}

.bottom-svg-contaimner {
  display: flex;
}



@media (max-width: 1300px) {
  .projects {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;

  }
}

.projects {

  display: grid;
  grid-template-columns: repeat(3, 1fr);


  .product-box {
    width: 400px;
    // height: 350px;
    border-radius: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 1rem;
    margin-bottom: 3rem;
    background-color: #494949;
    box-shadow: 0 0 20px rgba(66, 66, 66, 0.759);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .product-box:hover {
    background-color: #999999;
    box-shadow: 0 0 20px #8000806f;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: scale(1.05);
    /* Adjust the scale value as needed */
  }


  .skill-cards {
    display: flex;
    justify-content: flex-start;
  }

  //skill card TAG
  .skill-card {
    margin: 0.3rem;
    background-color: purple;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;

    p {
      margin: 0.3rem;
      padding: 4%;
    }
  }


  .project-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  h3 {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-size: 20px;
    text-align: center;
    margin: 0.2rem;
  }

  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    margin: 0.2rem;
  }

  p {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin: 0.2rem;
  }

}

.product-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.post-link {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  a {
    color: white;
  }
}

.skill-section {
  margin: auto;
  display: flex;
  flex-direction: row;

}

.project-img-smaller {
  width: 100%;
  border-radius: 10px;
  height: 523px;
  object-fit: cover;
}

.aboutme-text {
  display: flex;
  z-index: 5;
  margin: 1rem;
  // padding: 1rem;
  width: 100%;
  border-radius: 10px;
  background-color: #494949;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  a {
    text-decoration: none;
    color: white;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  a:hover {
    color: #800080;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  svg {
    font-size: 25px;
  }


  .aboutme-text-left {
    width: 50%;
    padding: 0.5rem;
  }

  .aboutme-text-right {
    width: 50%;
    padding: 0.5rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
    grid-gap: 20px;


    .icon {
      max-width: 100%;
      height: auto;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .icon-container {
      position: relative;
      display: inline-block;

    }



    .hidden-skill-text {
      position: absolute;
      top: -50px;
      /* Adjust the distance from the icon */
      left: 50%;
      transform: translateX(-50%);
      display: none;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      border-radius: 10px;
      z-index: 10;
      width: max-content;
      opacity: 0;
    }

    .icon-container:hover .hidden-skill-text {
      display: block;
      opacity: 1;
      top: -65px;
    }

    .icon:hover {
      transform: scale(1.2);

    }
  }
}



.ferdigheter {
  margin: 1rem;
  padding: 1rem;
  width: 550px;
  border-radius: 10px;
  background-color: #494949;
}


//light mode

$base-color: #eeeeee;
$secondary-color: #d0d0d0;
$text-color: #000000;
$link-color: #ff5e00;

//switch


.toggle-switch {
  display: inline-block;
  width: 20px;
  height: 30px;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
}

.toggle-switch-slider {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  transition: all 0.3s;
  border-radius: 10px;

  svg {
    fill: rgb(255, 162, 0);
  }
}

.toggle-switch-slider.dark-mode {
  background-color: #333;
  justify-content: flex-end;

  svg {
    fill: purple;
  }
}


.toggle-switch-slider.dark-mode:before {
  transform: translateX(100%);
}



.light-mode {
  background-color: $base-color;
  color: black;

  .icons {
    svg {
      fill: black
    }

    svg:hover {
      fill: $link-color
    }
  }

  .webbody {
    background-color: $base-color;
    color: black;
  }

  .svg-wave {
    fill: $secondary-color;
  }

  nav {
    color: black;

    svg {
      color: black;
    }

    a:hover {
      color: $link-color;
    }
  }

  .nav-desktop a {
    color: black;
  }

  a {
    color: black;
  }

  nav {
    background-color: $secondary-color;
  }

  .aboutme-text {
    color: #000000;
    background-color: $secondary-color;
  }

  .aboutme-text a:hover {
    color: $link-color;
  }

  .line {
    background-color: $secondary-color;
  }

  .product-box {
    background-color: $base-color;
  }

  .skill-card {
    background-color: $secondary-color;
  }

  .product-box:hover {
    background-color: #ebebeb;
    color: black;
  }

  .svg-wave-bot {
    fill: $secondary-color;
  }

  footer {
    background-color: $secondary-color;
    color: black;
  }
}