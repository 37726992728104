footer {
    background-color: #232323;
    height: 120px;
    color: white;
    display: flex;
    justify-content: space-evenly;
  
  //FOR 4k
    // position: absolute;
    // bottom: 0;
    // width: 100%;
  
    p{
    font-size: 16px;
    padding: 3rem;
    margin: 0%;
    opacity: 0.8;
    }
  }
  
  
//   nav{
//     height: 120px;
//     background-color: #151515;F
//     position: sticky;
//     display: flex;
//     justify-content: center;
//     position: sticky;
//     top: 0px;
//     z-index: 2;
//     p{
//         margin: 3rem;
//     }
//   }

// .nav-wrapweb{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1rem;
//   a{
//     background-color: #494949; 
//     display: flex;
//     align-items: center;
//     width: 80px;
//     height: 40px;
//     padding: 1rem;
//     color: white;
//     justify-content: center;
//     border-radius: 10px;
//     text-align: center;
//     text-decoration: none;
//   }
//   a:hover{
//     background-color: purple;
//   }
// }

  
//   .trednav{
//     height: 80px;;
//     background-color: #d0e4ff;
//     position: relative;

//     .nav-wrap{
//       display: flex;
//       align-items: center;
//       gap: 5rem;
//     }
//       a{
//         font-size: 20px;
//         color: black;
//         text-decoration: none;

//       }
//   }

//   .tredfooter{
//     background-color: aliceblue;

//     .female{
//         position: absolute;
//         width: 600px;
//     }
//   }


nav {
  backdrop-filter: blur(10px);
  padding: 0.5rem 0 0.5rem 0;
  z-index: 99;
  position: fixed;
  width: 100%;
  background-color: #232323;
  color: white;
}

.nav-link-wrap{
  display: flex;
  align-items: center;
  margin-right: 3rem;
}

.nav-wrapweb {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.nav-desktop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a{
    color: white;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  a:hover{
    color: purple;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  }
}

.nav-wrapmobile {
  display: none;
  flex-direction: column;
  align-items: center;
}


.show-nav {
  display: none;
}

nav a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
  margin: 0 1rem;
  transition: color 0.3s ease-in-out;
}

.hamburger-menu {
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: white;
}

@media only screen and (max-width: 768px) {



  .mobile-navbar-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .nav-desktop{
    visibility: hidden;
    position: absolute;
    width: 1px;
  }


  .hamburger-menu {
    display: block;
    padding: 0;
    margin-left: 0.5rem;
  }

  .nav-wrapmobile {
    width: 100%;
    margin-top: 1rem;
  }

  .nav-wrapmobile a {
    margin: 0.5rem 0;
  }

  .show-nav {
    display: flex;
    align-items: flex-start;
  }

  .show-nav a {
    display: block;
  }
}
