//3d site

@media screen and (max-width: 600px) {

  main {
    padding: 0;
  }

  .projects3d-wrapper {
    .project-img3d {
      border-radius: 0;
    }

    .projects3d {
      width: 100%;
      height: 100%;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
  }
}


.breadcrumbs {
  position: absolute;
  top: 87px;
  color: rgb(129, 129, 129);
  opacity: 0.5;

  a {
    color: rgb(170, 170, 170)
  }


}


//Web 

@media screen and (max-width: 600px) {


  .projects .product-box {
    width: 100%;
  }

  .header-post-3d {
    height: 100px;
  }

  .big-img {
    height: 200px !important;
  }

  .aboutme-text {
    width: 90%;
  }

  .skill-section {
    flex-direction: column;

    .aboutme-text {
      width: 250px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  .projects-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .project-img-smaller {
    width: 100%;
    height: auto;
  }

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .toggle-switch-mobile {
    position: absolute;
    right: 20px;
    top: 19px;
  }

  .section-header {
    flex-direction: column;
  }

  .project-img {
    height: 100% !important;
    width: 100%;
  }

  main {
    h2 {
      text-align: center;
      font-size: 36px;
    }

    section {
      margin: 0.5rem;
    }
  }

  .selectable-img {
    visibility: hidden;
    position: absolute;
    width: 1px;
  }


  .skill-section {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .projects {
    height: auto;
  }

  .projects3d {
    box-shadow: 0 0 20px rgb(135 135 135);
  }

  .trednav .nav-wrap {
    gap: 0rem;
    flex-direction: column;
  }
}